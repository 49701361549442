import * as React from "react"

import Layout from "../components/layouts/layout"
import Seo from "../components/utils/seo"
import PrivacidadHero from "../components/sections/privacidadHero"
import PoliticaPrivacidad from "../components/sections/politicaPrivacidad"

const Privacidad = () => (
    <Layout>
        <Seo title="Política de privacidad | Danios Digital" />
        <PrivacidadHero />
        <PoliticaPrivacidad />
    </Layout>
)

export default Privacidad