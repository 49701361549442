import { Box, Center, Heading, Text } from '@chakra-ui/react'
import * as React from 'react'

const PrivacidadHero = () => {
    return (
        <Box
            as="section"
            bg="gray.800"
            py="12"

            position="relative"
            h={{ base: '280px', md: '320px' }}
            bgImage="url(https://images.unsplash.com/photo-1450101499163-c8848c66ca85?ixlib=rb-1.2.1&raw_url=true&q=80&fm=jpg&crop=entropy&cs=tinysrgb&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470)"
            bgSize="cover"
            bgPosition="center"
            _after={{
                content: `""`,
                display: 'block',
                w: 'full',
                h: 'full',
                bg: 'blackAlpha.800',
                position: 'absolute',
                inset: 0,
                zIndex: 0,
            }}
        >
            <Box
                maxW={{ base: 'xl', md: '7xl' }}
                mx="auto"
                px={{ base: '6', md: '8' }}
                h="full"
                zIndex={1}
                position="relative"
            >
                <Center flexDirection="column" textAlign="center" color="white" h="full">
                    <Heading size="2xl" fontWeight="extrabold">
                        POLÍTICA DE PRIVACIDAD
                    </Heading>
                </Center>
            </Box>

        </Box>
    )
}

export default PrivacidadHero